.employee-register {
    background-color: #fff;
    min-height: 400px;
}

.employee-register > div {
    margin: 0 auto;
    padding: 30px 10px;
    max-width: 600px;
    text-align: center;
}

.employee-register > div h1 {
    font-size: 30px;
    font-weight: bold;
    color: #3f8bc4;
}

.employee-register > div .email {
    color: #3f8bc4;
}